import React, { useState } from 'react';
import styled, { ThemeContext } from "styled-components";
import debounceFn from 'debounce-fn';

const Overlayer = () => {

    const theme = React.useContext(ThemeContext);

    const [opacityAmount, setOpacityAmount] = useState<number>(0.35);

    const opacityAnimationParameters: AnimationParameters = {
        scrollStart: 10.0,
        scrollEnd: 300.0,
        amountStart: 0.35,
        amountEnd: 0.5,
        curveFunction: easeOutCurve
    }

    React.useLayoutEffect(() => {
        const updatePosition = () => {
            setOpacityAmount(getAnimationAmount(window.scrollY, opacityAnimationParameters))
        }
        const animate = debounceFn(updatePosition, { wait: 12 })
        window.addEventListener('scroll', animate);
        animate();
        return () => window.removeEventListener('scroll', animate);
    });

    return (
        <>
            <BackgroundOverlayer theme={theme} opacity={`${opacityAmount}`} />
        </>
    );
}

interface AnimationParameters {
    scrollStart: number;
    scrollEnd: number;
    amountStart: number;
    amountEnd: number;
    curveFunction: Function;
}

function getAnimationAmount(scroll: number, animationParameters: AnimationParameters) {
    const progress = (scroll - animationParameters.scrollStart) / (animationParameters.scrollEnd - animationParameters.scrollStart);
    if (progress <= 0) {
        return animationParameters.amountStart
    } else if (progress >= 1) {
        return animationParameters.amountEnd
    } else {
        return animationParameters.amountStart + animationParameters.curveFunction(progress) * (animationParameters.amountEnd - animationParameters.amountStart)
    }
}

function easeOutCurve(progress: number) {
    return Math.atan(Math.PI * progress) / Math.atan(Math.PI)
}

interface ItemProps {
    opacity?: string;
    imagePath?: string;
    transform?: string;
    theme?: object;
}

const BackgroundOverlayer = styled.div.attrs<ItemProps>(
    ({ opacity }) => ({ style: { 'opacity': opacity } })) <ItemProps>`
    background: ${props => props.theme.color.overlayer};
    z-index: 0;
    top: -10vh;
    will-change: opacity;
    width: 120%;
    height: 120vh;
    position: fixed;
`;

export default Overlayer;
