import React, { useEffect } from 'react';
import Style, { ThemeContext } from 'styled-components';
import Label from '../../components/Label';
import { ColorMode, ColorStyle, FontStyle, FontType } from '../../utils/types/types';
import { useHistory } from "react-router-dom";

function WhatWeDoSection() {
    const theme = React.useContext(ThemeContext);
    const history = useHistory();

    // on page load, remove anchor (example if click on "who-we-are")
    useEffect(() => history.push(""), [history])
    return (
        <MainContainer id="who-we-are">
            <Label text='what_we_are.title' margin={`${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.huge} ${theme.spacing.medium}`} type={FontType.title} fontStyle={FontStyle.header1} color={ColorStyle.overImage} mode={ColorMode.light} align='center'></Label>
            <HorizontalStack theme={theme}>
                <CardContainer theme={theme}>
                    <Label text='what_we_are.section.1.title' type={FontType.body} fontStyle={FontStyle.header4} color={ColorStyle.overImage} mode={ColorMode.light} align='left'></Label>
                    <Label text='what_we_are.section.1.description' type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.overImage} mode={ColorMode.light} align='left'></Label>
                </CardContainer>
                <CardContainer theme={theme}>
                    <Label text='what_we_are.section.2.title' type={FontType.body} fontStyle={FontStyle.header4} color={ColorStyle.overImage} mode={ColorMode.light} align='left'></Label>
                    <Label text='what_we_are.section.2.description' type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.overImage} mode={ColorMode.light} align='left'></Label>
                </CardContainer>
                <CardContainer theme={theme}>
                    <Label text='what_we_are.section.3.title' type={FontType.body} fontStyle={FontStyle.header4} color={ColorStyle.overImage} mode={ColorMode.light} align='left'></Label>
                    <Label text='what_we_are.section.3.description' type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.overImage} mode={ColorMode.light} align='left'></Label>
                </CardContainer>
            </HorizontalStack>
        </MainContainer>
    );
}

interface ContentProps {
    theme: object;
}

const HorizontalStack = Style.div<ContentProps>`
    box-shadow: 0px 0px 64px rgb(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0px ${props => props.theme.desktop ? '0px' : props => props.theme.spacing.huge} 0px;
    margin: 0px ${props => props.theme.spacing.regular} ${props => props.theme.spacing.extreme} ${props => props.theme.spacing.regular};
    display: flex;
    position: relative;
`

const CardContainer = Style.div<ContentProps>`
    margin: ${props => props.theme.spacing.medium};
    max-width: ${props => props.theme.cardWidth.normal};
    position: relative;
    display: inline-block;
`;

const MainContainer = Style.div`
    padding-top: 32px;
`;

export default WhatWeDoSection;
