import React from 'react';
import Message from '../../components/Message';
import SectionHeader from '../../components/SectionHeader';
import { ColorMode, FontStyle, MessageStyle } from '../../utils/types/types';

function ConversationSection() {
    return (
        <>
            <SectionHeader title='conversation.header.title' subtitle='conversation.header.subtitle' titleStyle={FontStyle.header2} mode={ColorMode.light}></SectionHeader>
            <div className="verticalStack">
                <Message iconPath='./assets/emojis/gregoire.png' localKey='conversation.message.president' messageStyle={MessageStyle.receive}></Message>
                <Message iconPath='./assets/emojis/outpass.png' localKey='conversation.message.client.1' messageStyle={MessageStyle.send}></Message>
                {/* <Message iconPath='./assets/emojis/gregoire.png' localKey='conversation.message.president' messageStyle={MessageStyle.receive}></Message> */}
                <Message iconPath='./assets/emojis/jean.png' localKey='conversation.message.director' messageStyle={MessageStyle.receive}></Message>
                <Message iconPath='./assets/emojis/sorbonne.png' localKey='conversation.message.client.2' messageStyle={MessageStyle.send}></Message>
            </div>
        </>
    );
}

export default ConversationSection;
