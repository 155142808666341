import React, { useState } from 'react';
import styled from "styled-components";
import debounceFn from 'debounce-fn';

interface Props {
    children?: any
    imagePath: string
}

const ImageAnimationLayer = ({ children, imagePath }: Props) => {

    const [scaleAmount, setScaleAmount] = useState<number>(1.1);

    const scaleAnimationParameters: AnimationParameters = {
        scrollStart: 1.0,
        scrollEnd: 400.0,
        amountStart: 1.01,
        amountEnd: 1.2,
        curveFunction: easeOutCurve
    }

    React.useLayoutEffect(() => {
        const updatePosition = () => {
            setScaleAmount(getAnimationAmount(window.scrollY, scaleAnimationParameters))
        }
        const animate = debounceFn(updatePosition, { wait: 12 })
        window.addEventListener('scroll', animate);
        animate();
        return () => window.removeEventListener('scroll', animate);
    });

    return <BackgroundImage scale={scaleAmount} imagePath={imagePath}>{children}</BackgroundImage>
}

interface AnimationParameters {
    scrollStart: number;
    scrollEnd: number;
    amountStart: number;
    amountEnd: number;
    curveFunction: Function;
}

function getAnimationAmount(scroll: number, animationParameters: AnimationParameters) {
    const progress = (scroll - animationParameters.scrollStart) / (animationParameters.scrollEnd - animationParameters.scrollStart);
    if (progress <= 0) {
        return animationParameters.amountStart
    } else if (progress >= 1) {
        return animationParameters.amountEnd
    } else {
        return animationParameters.amountStart + animationParameters.curveFunction(progress) * (animationParameters.amountEnd - animationParameters.amountStart)
    }
}

function easeOutCurve(progress: number) {
    return Math.atan(Math.PI * progress) / Math.atan(Math.PI)
}

interface ItemProps {
    blur?: string;
    imagePath: string;
    scale: number;
    theme?: object;
}

const BackgroundImage = styled.div.attrs<ItemProps>(({ scale }) =>
    ({ style: { 'transform': `scale(${scale})` } })) <ItemProps>`
    will-change: transform;
    height: 100vh;
    width: 100%;
    position: fixed;
    background: url(${props => props.imagePath});
    background-size: cover;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    transform: scale(1.01);
    top: 0;
`;
export default ImageAnimationLayer;
