import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType } from '../../../utils/types/types'
import Label from '../../../components/Label';

interface ClientCardProps {
    iconPath: string;
    info: string;
    body: string;
    link: string;
}

const ClientCard = ({ iconPath, info, body, link }: ClientCardProps) => {
    const theme = React.useContext(ThemeContext);
    const style: React.CSSProperties = {
        objectFit: 'contain',
        width: '100%',
    }
    return (
        <Container href={link} theme={theme}>
            <img style={style} src={iconPath} height={theme.clientCardIconHeight} alt="client"/>
            <BottomMarginBox theme={theme}>
                <Label text={info} type={FontType.body} fontStyle={FontStyle.info} color={ColorStyle.info} mode={ColorMode.dark} align='center'></Label>
            </BottomMarginBox>
            <Label text={body} type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.body} mode={ColorMode.dark} align='left'></Label>
        </Container>
    )
}

interface ContentProps {
    theme: object;
}

const Container = Style.a<ContentProps>`
    text-decoration:none;
    margin: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
    padding: ${props => props.theme.spacing.large};
    max-width: ${props => props.theme.clientCardWidth};
    ${props => props.theme.color.darkCard};
    position: relative;
    display: inline-block;
    overflow: hidden;
    ${props => props.theme.shadow.light};
    border-radius: ${props => props.theme.borderRadius.regular};
    transition: 0.3s;
    z-index: 10;
    &:hover {
        transform: scale(1.02);
        ${props => props.theme.shadow.focused};
    }
`;

const BottomMarginBox = Style.div<ContentProps>`
    margin: 0px 0px ${props => props.theme.spacing.medium} 0px;
`;

export default ClientCard;
