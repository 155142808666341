import Card from '../../components/Card';
import { CardSize } from '../../utils/types/types';
import Style, { ThemeContext } from "styled-components";
import { useContext } from 'react';

const OurMission = () =>
    <MainContainer>
        <HorizontalStack theme={useContext(ThemeContext)}>
            <Card size={CardSize.large} title='what_we_do.1.title' body='what_we_do.1.description' />
            <Card size={CardSize.large} title='what_we_do.2.title' body='what_we_do.2.description' />
        </HorizontalStack>
    </MainContainer>

interface ContentProps {
    theme: object;
}

const MainContainer = Style.div`
    position: relative;
    background: ${props => props.theme.color.background};
`;

const HorizontalStack = Style.div<ContentProps>`
    flex-wrap: wrap;
    justify-content: center;
    margin: ${p => p.theme.spacing.section} 0px -100px 0px;
    width: 100%;
    display: flex;
    top: -100px;
    position: relative;
`;

export default OurMission;
