import React from 'react';
import FooterCard from '../../components/FooterCard';
import FooterSection from '../../components/FooterContainer';
import SectionHeader from '../../components/SectionHeader';
import { ColorMode, FontStyle } from '../../utils/types/types';

function ContactSection() {
    const linkStyle: React.CSSProperties = { color: '#45464F' }
    return (
        <>
            <SectionHeader title='write_next_message.header.title' subtitle='write_next_message.header.subtitle' titleStyle={FontStyle.header2} mode={ColorMode.light}></SectionHeader>
            <FooterSection>
                <FooterCard iconPath='./assets/icons/phone.png' title='footer.1.title' body='footer.1.description'>Par mail à <a href='mailto:hello@creastel.com' style={linkStyle}>adresse mail</a> ou en demandant un rendez-vous téléphonique sur <a href='https://form.typeform.com/to/dlw44Evq' style={linkStyle}>formulaire</a> .</FooterCard>
                <FooterCard iconPath='./assets/icons/coffee.png' title='footer.2.title' body='footer.2.description'></FooterCard>
                <FooterCard iconPath='./assets/icons/magic.png' title='footer.3.title' body='footer.3.description'></FooterCard>
            </FooterSection>
        </>
    );
}

export default ContactSection;
