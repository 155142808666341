import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType, MessageStyle } from '../../utils/types/types'
import Label from '../Label';

interface MessageProps {
    iconPath: string;
    localKey: string;
    messageStyle: MessageStyle;
}

const Message = ({ iconPath, localKey, messageStyle }: MessageProps) => {
    const theme = React.useContext(ThemeContext);
    const textColor = messageStyle === MessageStyle.receive ? ColorMode.light : ColorMode.dark;
    return (
        <Container theme={theme}>
            <Buble theme={theme} messageStyle={messageStyle}>
                <Label margin={`0px`} text={`${localKey}.author`} type={FontType.title} fontStyle={FontStyle.header4} color={ColorStyle.title} mode={textColor} align='left'></Label>
                <Label margin={`${theme.spacing.tiny} 0px 0px 0px`} text={`${localKey}.infos`} type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.subtitle} mode={textColor} align='left'></Label>
                <Label margin={`${theme.spacing.small} 0px 0px 0px`} text={`${localKey}.text`} type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.body} mode={textColor} align='left'></Label>
                {messageStyle === MessageStyle.receive ? <ReceivePointer theme={theme}></ReceivePointer> : <SendPointer theme={theme}></SendPointer>}
            </Buble>
            <MessageIcon theme={theme} messageStyle={messageStyle}>
                <img alt="message-icon" src={iconPath} height={theme.messageIconDiameter} width={theme.messageIconDiameter}></img>
            </MessageIcon>
        </Container>
    )
}

const SendPointer = Style.div<{
    theme: object;
}>`
    height: 14px;
	width: 14px;
	background: ${props => props.theme.color.accent1};
	transform: rotate(45deg);
	border-radius: 0 3px 0 0;
	position: absolute;
	right: -7px;
    bottom: 28px;
`;

const ReceivePointer = Style.div<{
    theme: object;
}>`
    height: 14px;
	width: 14px;
	background: ${props => props.theme.color.foreground2};
	transform: rotate(45deg);
	border-radius: 0 0 0 3px;
	position: absolute;
	left: -7px;
    bottom: 28px;
`;

const MessageIcon = Style.div<{
    theme: object;
    messageStyle: MessageStyle;
}>`
    background: ${props => props.messageStyle === MessageStyle.receive ? props.theme.color.foreground : props.theme.color.accent};
    width: ${props => props.theme.messageIconDiameter};
    height: ${props => props.theme.messageIconDiameter};
    overflow: hidden;
    border-radius: ${props => props.theme.borderRadius.rounded};

    /* Inside Auto Layout */

    flex: none;
    order: ${props => props.messageStyle === MessageStyle.receive ? '0' : '1'};
    flex-grow: 0;
    margin: 0px 0px;
`;

const Buble = Style.div<{
    theme: object;
    messageStyle: MessageStyle;
}>`
    max-width: ${props => props.theme.messageWidth};
    background: ${props => props.messageStyle === MessageStyle.receive ? props.theme.color.foreground : props.theme.color.accent};
    overflow: hidden;
    border-radius: ${props => props.theme.borderRadius.regular};
    transition: 0.3s;
    z-index: 10;
    ${props => props.theme.shadow.light};
    transform: scale(1);
    &:hover {
        transform: scale(1.006);
        ${props => props.theme.shadow.focused};
    }

    /* Inside Auto Layout */

    order: ${props => props.messageStyle === MessageStyle.send ? '0' : '1'};
    align-self: stretch;
    flex-grow: 0;
    margin: 0px ${props => props.theme.spacing.small};

    /* Auto Layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large};
`;

const Container = Style.div<{
    theme: object;
}>`
    margin: ${props => props.theme.spacing.small};
    position: relative;
    display: inline-block;

    /* Auto Layout */

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
`;

export default Message;
