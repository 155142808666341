import React, { useLayoutEffect, useState } from 'react';
import { ThemeProvider as ThemeProviderBase } from 'styled-components';

// Colors
const colors = {
    background: '#FFFFFF',
    foreground: 'linear-gradient(296.93deg, #E9E9E9, #F3F3F3)',
    accent: 'linear-gradient(296.93deg, #1F2830, #0C131E)',
    foreground1: '#E9E9E9',
    foreground2: '#F3F3F3',
    accent1: '#1F2830',
    accent2: '#0C131E',
    overlayer: 'rgba(30, 30, 62, 1)',
    darkCard: 'background: rgba(15, 15, 25, 1)',
    text: {
        light: {
            contrast: '#000000',
            title: '#1E1E22',
            subtitle: '#686870',
            body: '#45464F',
            info: '#686870',
            overImage: '#FFFFFF; text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2)'
        },
        dark: {
            contrast: '#FFFFFF',
            title: '#FFFFFF',
            subtitle: '#C5C6C8',
            body: '#F1F1F1',
            info: '#C5C6C8',
            overImage: '#FFFFFF; text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2)'
        },
    },
}

// Fonts
const fonts = {
    title: 'font-family: Juana',
    body: 'font-family: Inter',
}

// Shadows
const shadowColor = 'rgba(0, 0, 0, 0.05)';
const focusedShadowColor = 'rgba(0, 0, 0, 0.07)';
const desktopShadow = '8px 4px 64px';
const focusedDesktopShadow = '16px 8px 76px';
const mobileShadow = '8px 4px 64px';
const focusedMobileShadow = '16px 8px 76px';

// Themes
const desktopTheme = {
    // colors
    color: colors,
    // shadows
    shadow: {
        light: `box-shadow: ${desktopShadow} ${shadowColor}`,
        focused: `box-shadow: ${focusedDesktopShadow} ${focusedShadowColor}`,
    },
    // Border Radius
    borderRadius: {
        regular: '16px',
        rounded: '100%',
    },
    // fonts
    fontType: fonts,
    fontStyle: {
        italicHeader1: `font-size:52px; font-weight: 400; line-height: 130%; font-style: italic`,
        header1: `font-size:52px; font-weight: 600; line-height: 130%`,
        header2: `font-size:34px; font-weight: 600; line-height: 130%`,
        header3: `font-size:24px; font-weight: 600; line-height: 130%`,
        header4: `font-size:19px; font-weight: 600; line-height: 130%; letter-spacing: 0.1px`,
        button: `font-size:18px; font-weight: 600; line-height: 130%; letter-spacing: 0.1px`,
        bigBody: `font-size:17px; font-weight: 500; line-height: 140%`,
        body: `font-size:15px; font-weight: 500; line-height: 140%`,
        info: `font-size:14px; font-weight: 500; line-height: 130%`,
    },
    // spacings
    spacing: {
        micro: '1px',
        tiny: '8px',
        small: '12px',
        body: '14px',
        regular: '20px',
        medium: '24px',
        large: '36px',
        huge: '48px',
        extreme: '128px',
        section: '200px'
    },
    // transitions
    transition: 'transition: 0.1s;',
    // measures
    cardWidth: {
        large: '350px',
        normal: '250px',
    },
    clientCardWidth: '200px',
    clientCardIconHeight: '60px',
    cardImageHeight: {
        large: '280px',
        normal: '160px',
    },
    sectionHeaderWidth: '800px',
    messageIconDiameter: '80px',
    messageWidth: '600px',
    descriptionWidth: '500px',
    footerCardWidth: '300px',
    iconDiameter: '40px',
    desktop: true
};

const mobileTheme = {
    // colors
    color: colors,
    // shadows
    shadow: {
        light: `box-shadow: ${mobileShadow} ${shadowColor}`,
        focused: `box-shadow: ${focusedMobileShadow} ${focusedShadowColor}`,
    },
    // Border Radius
    borderRadius: {
        regular: '12px',
        rounded: '100%',
    },
    // fonts
    fontType: fonts,
    fontStyle: {
        italicHeader1: `font-size:40px; font-weight: 400; line-height: 130%; font-style: italic`,
        header1: `font-size:40px; font-weight: 600; line-height: 130%`,
        header2: `font-size:28px; font-weight: 600; line-height: 130%`,
        header3: `font-size:22px; font-weight: 600; line-height: 130%`,
        header4: `font-size:18px; font-weight: 600; line-height: 130%`,
        button: `font-size:16px; font-weight: 600; line-height: 130%`,
        bigBody: `font-size:16px; font-weight: 500; line-height: 130%`,
        body: `font-size:16px; font-weight: 500; line-height: 130%`,
        info: `font-size:16px; font-weight: 500; line-height: 130%`,
    },
    // spacings
    spacing: {
        micro: '0px',
        tiny: '6px',
        small: '10px',
        body: '13px',
        regular: '16px',
        medium: '20px',
        large: '28px',
        huge: '36px',
        extreme: '68px',
        section: '100px'
    },
    // transitions
    transition: 'transition: 0.1s;',
    // measures
    cardWidth: {
        large: '300px',
        normal: '300px',
    },
    clientCardWidth: '250px',
    clientCardIconHeight: '40px',
    cardImageHeight: {
        large: '200px',
        normal: '150px',
    },
    sectionHeaderWidth: '500px',
    messageIconDiameter: '50px',
    messageWidth: '450px',
    descriptionWidth: '400px',
    footerCardWidth: '200px',
    iconDiameter: '30px',
    desktop: false
};

const ThemeProvider: React.FC = ({ children }): JSX.Element => {

    const [actualTheme, setActualTheme] = useState<Object>(desktopTheme);

    useLayoutEffect(() => {
        const updateSize = () => setActualTheme(window.innerWidth > 1000 ? desktopTheme : mobileTheme);
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return (
        <ThemeProviderBase theme={actualTheme}>
            {children}
        </ThemeProviderBase>
    );
};

export default ThemeProvider;