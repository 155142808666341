import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType } from '../../utils/types/types'
import Label from '../Label';

interface SectionHeaderProps {
    titleStyle: FontStyle;
    title: string;
    subtitle: string;
    mode: ColorMode;
}

const SectionHeader = ({ titleStyle, title, subtitle, mode }: SectionHeaderProps) => {
    const theme = React.useContext(ThemeContext);
    return (
        <Content theme={theme}>
            <Label text={title} type={FontType.title} fontStyle={titleStyle} color={ColorStyle.title} mode={mode} align='center'></Label>
            <Label text={subtitle} type={FontType.body} fontStyle={FontStyle.bigBody} color={ColorStyle.title} mode={mode} align='center'></Label>
        </Content>
    );
}

interface ContentProps {
    theme: object;
}

const Content = Style.div<ContentProps>`
    max-width: ${props => props.theme.sectionHeaderWidth};
    position: relative;
    margin: 12px auto 0px auto;
    padding: ${props => `${props.theme.spacing.large} ${props.theme.spacing.huge}`}
    `;


export default SectionHeader;
