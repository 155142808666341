import ClientCard from './ClientCard';
import Style from 'styled-components';
import ImageCardSection from '../../components/ImageCardContainer';
import SectionHeader from '../../components/SectionHeader';
import { ColorMode, FontStyle } from '../../utils/types/types';

function OurClientsSection() {
    return (
        <ImageCardSection imagePath='./assets/background/our_clients.jpg'>
            <SectionHeader title='our_clients.header.title' subtitle='our_clients.header.subtitle' titleStyle={FontStyle.header1} mode={ColorMode.dark}></SectionHeader>
            <HorizontalStack>
                <ClientCard iconPath='./assets/clients/outpass.png' info='our_clients.outpass.client_info' body='our_clients.outpass.body' link='https://apps.apple.com/app/outpass/id1532079986'></ClientCard>
                <ClientCard iconPath='./assets/clients/etb.png' info='our_clients.etb.client_info' body='our_clients.etb.body' link='https://www.eurodistrictbasel.eu/fr/services/actualites/actualites/video-1-3-vis-a-vis-un-lieu-de-loisirs-et-de-rencontre.html'></ClientCard>
                <ClientCard iconPath='./assets/clients/sorbonne.png' info='our_clients.sorbonne.client_info' body='our_clients.sorbonne.body'link='https://www.sorbonne-universite.fr'></ClientCard>
                <ClientCard iconPath='./assets/clients/hooderie.png' info='our_clients.hooderie.client_info' body='our_clients.hooderie.body'link='https://hooderie.com'></ClientCard>
            </HorizontalStack>
        </ImageCardSection>
    );
}

const HorizontalStack = Style.div<{}>`
    box-shadow: 0px 0px 64px rgb(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    display: flex;
    position: relative;
`

export default OurClientsSection;
