import React, { useState } from 'react';
import styled from "styled-components";
import debounceFn from 'debounce-fn';

interface props {
    children?: any;
}

const OpacityLayer = ({ children }: props) => {

    const [opacityAmount, setOpacityAmount] = useState<number>(0);
    const [show, setShow] = useState<boolean>(true);

    const opacityAnimationParameters: AnimationParameters = {
        scrollStart: 10.0,
        scrollEnd: 100.0,
        amountStart: 1.0,
        amountEnd: 0.0,
        curveFunction: easeOutCurve
    }

    React.useLayoutEffect(() => {
        const updatePosition = () => {
            setOpacityAmount(getAnimationAmount(window.scrollY, opacityAnimationParameters))
            setShow(opacityAmount > 0)
        }
        const animate = debounceFn(updatePosition, { wait: 12 })
        window.addEventListener('scroll', animate);
        animate();
        return () => window.removeEventListener('scroll', animate);
    });

    return (
        <Container display={show ? 'inherit' : 'none'} opacity={`${opacityAmount}`}>
            {children}
        </Container>
    );
}

interface AnimationParameters {
    scrollStart: number;
    scrollEnd: number;
    amountStart: number;
    amountEnd: number;
    curveFunction: Function;
}

function getAnimationAmount(scroll: number, animationParameters: AnimationParameters) {
    const progress = (scroll - animationParameters.scrollStart) / (animationParameters.scrollEnd - animationParameters.scrollStart);
    if (progress <= 0) {
        return animationParameters.amountStart
    } else if (progress >= 1) {
        return animationParameters.amountEnd
    } else {
        return animationParameters.amountStart + animationParameters.curveFunction(progress) * (animationParameters.amountEnd - animationParameters.amountStart)
    }
}

function easeOutCurve(progress: number) {
    return Math.atan(Math.PI * progress) / Math.atan(Math.PI)
}

interface ItemProps {
    opacity: string;
    display: string;
}

const Container = styled.div.attrs<ItemProps>(
    ({ opacity, display }) => ({ style: { opacity } })) <ItemProps>`
    will-change: opacity;
    ${p => p.display === 'none' && 'pointer-events: none; cursor:default;'}
`;

export default OpacityLayer;
