import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// wording sheet key = 1jJ9DqgVGwWgFfDfItQL8_fyBEO7L9AYQwA6jlQ1HmPA
// wording link = https://docs.google.com/spreadsheets/d/1jJ9DqgVGwWgFfDfItQL8_fyBEO7L9AYQwA6jlQ1HmPA/edit#gid=0
i18n.use(initReactI18next).init({
    resources: {
        fr: {
            translations: {
                "welcome.title": "Agence de création",
                "welcome.subtitle.1": "numérique.",
                "welcome.subtitle.2": "impactante.",
                "welcome.subtitle.3": "passionnée.",
                "welcome.go_down.description": "Nous concevons des <1>expériences</1> numériques inclusives et humaines.",
                "welcome.hiring.button": "Creastel recrute !",
                "what_we_are.title": "Passion, innovation, impact.",
                "what_we_are.section.1.title": "Des expériences plus que des fonctionnalités.",
                "what_we_are.section.1.description": "L’utilisateur est au cœur de notre processus créatif. Notre passion, c'est de répondre parfaitement à ses besoins et ses envies.",
                "what_we_are.section.2.title": "L’innovation est notre force.",
                "what_we_are.section.2.description": "Grâce à notre expertise technique, nous concevons des solutions innovantes spécifiques, réalisables et sur mesure.",
                "what_we_are.section.3.title": "Des créations impactantes.",
                "what_we_are.section.3.description": "Nous travaillons ensemble sur des projets impactant positivement votre audience. Notre but est de rendre vos idées puissantes.",
                "what_we_do.1.title": "Qu'est ce que Creastel ?",
                "what_we_do.1.description": "Creastel est une agence de création numérique française, fondée en 2021 par deux ingénieurs passionnés. Bien plus qu'une simple entreprise de services numériques, Creastel a pour ambition d'être un catalyseur d'idées innovantes.",
                "what_we_do.2.title": "Notre mission",
                "what_we_do.2.description": "Creastel vous accompagne pour définir, concevoir, développer et distribuer des solutions correspondant à vos besoins. De l'idéation à la réalisation, nos équipes travaillent de concert pour propulser votre projet sur le marché.",
                "we_are_passionate.header.title": "Notre équipe est passionnée",
                "we_are_passionate.header.subtitle": "Derrière <strong>Creastel</strong> se cache une dynamique chevronnée. Nous sommes convaincus que la confrontation de nos idées fait émerger des solutions uniques.",
                "we_are_passionate.1.title": "Designers",
                "we_are_passionate.1.description": "Nous utilisons des méthodes de conception centrées sur l'utilisateur pour itérer jusqu'à la meilleure solution.",
                "we_are_passionate.2.title": "Ingénieurs",
                "we_are_passionate.2.description": "Nos équipes sont notamment composées d'ingénieurs combinant une expertise technique forte à une grande curiosité.",
                "we_are_passionate.3.title": "Créateurs",
                "we_are_passionate.3.description": "Passionnés par le monde qui nous entoure, nous cultivons et tirons parti d'une dynamique de création au quotidien.",
                "our_clients.header.title": "Nos clients",
                "our_clients.header.subtitle": "Ils nous ont fait confiance et nous avons adoré travailler sur leurs projets.",
                "our_clients.outpass.client_info": "Réalisation d’une application mobile",
                "our_clients.outpass.body": "Creastel a accompagné OutPass lors de son processus de création d'activité et a notamment effectué la conception, la réalisation et la distribution de son application mobile.",
                "our_clients.sorbonne.client_info": "Réalisation d’une application web",
                "our_clients.sorbonne.body": "Les équipes de Creastel ont fait évoluer une solution web afin de permettre une visualisation claire et intuitive de données historiques pour le compte de la Sorbonne.",
                "our_clients.hooderie.client_info": "Création de supports de communication",
                "our_clients.hooderie.body": "Hooderie, marque de prêt à porter, fait appel à Creastel lors de ses campagnes publicitaires pour réaliser un certain nombre de supports de communication. ",
                "our_clients.etb.client_info": "Réalisation d'une mini-série vidéo bilingue",
                "our_clients.etb.body": "Portant sur le projet d’aménagement \"Vis-à-vis\". \"Creastel a su faire preuve d’un professionnalisme, d’une grande rigueur et d’une adaptabilité certaine au contexte international.\"",
                "conversation.header.title": "Rejoignez l'aventure !",
                "conversation.header.subtitle": "Creastel c'est de multiples projets réalisés et des centaines d'idées pour demain.<br/>Prenez part à la conversation :",
                "conversation.message.client.1.author": "Audric Cavelier",
                "conversation.message.client.1.infos": "PDG d'OutPass",
                "conversation.message.client.1.text": "Une équipe exceptionnelle et professionnelle à votre écoute, à chaque instant. Creastel a su transformer nos idées en propositions innovantes pour au final réaliser une application allant bien au delà de nos attentes. Merci pour leur implication continue dans tous nos projets (vidéos marketing, suivi administrateur, maintenance, conseils...)",
                "conversation.message.president.author": "Grégoire Thibaud",
                "conversation.message.president.infos": "Co-fondateur et étudiant ingénieur à l’UTC",
                "conversation.message.president.text": "Creastel, c’est ma façon de transposer ma passion dans un métier. Je mets mon dynamisme chaque jour au service de missions stimulantes à fort impact.",
                "conversation.message.client.2.author": "Lucia Orlandi",
                "conversation.message.client.2.infos": "Chercheuse, membre associée de l'unité Orient & Méditerranée de La Sorbonne",
                "conversation.message.client.2.text": "Une équipe de Creastel a réalisé le logiciel de gestion et de visualisation de ma base des données de recherche. Je suis vraiment satisfaite, et j'ai beaucoup apprécié la collaboration avec eux, pour la rigueur et la ponctualité dans le travail, ainsi que pour la flexibilité et la créativité avec lesquelles ils ont trouvé les meilleures solutions pour moi.",
                "conversation.message.director.author": "Jean Haberer",
                "conversation.message.director.infos": "Co-fondateur et étudiant ingénieur INSA Lyon",
                "conversation.message.director.text": "Creastel c'est travailler pour surprendre nos clients et nos utilisateurs avec des créations innovantes et uniques. C'est l'opportunité d'avoir un impact positif sur notre société. ",
                "write_next_message.header.title": "À vous d'écrire le prochain message !",
                "write_next_message.header.subtitle": "La conversation est ouverte. Rejoignez-la pour parler de vos projets. Que vous vouliez améliorer un service, faire parler de vous, ou même changer le monde, on a hâte d'échanger avec vous !",
                "footer.1.title": "Prenez contact avec nous.",
                "footer.1.description": "Par mail à <1>hello@creastel.com</1> ou en demandant un rendez-vous téléphonique sur <3>ce formulaire</3>. N'hésitez pas, nous sommes réactifs.",
                "footer.2.title": "Discutons de votre projet.",
                "footer.2.description": "Un membre de notre équipe prendra contact avec vous pour échanger autour de votre projet. Nous vous présenterons nos solutions sur mesure.",
                "footer.3.title": "En route vers de grandes aventures !",
                "footer.3.description": "Recevez nos propositions, itérons jusqu'à trouver celle qui vous ira comme un gant et changera peut-être le monde de demain...",
                "footer.copyright": "© Creastel SAS — 2021",
            }
        }, en: {
            translations: {
                "welcome.title": "",
                "welcome.subtitle.1": "",
                "welcome.subtitle.2": "",
                "welcome.subtitle.3": "",
                "welcome.go_down.description": "",
                "welcome.hiring.button": "",
                "what_we_are.title": "",
                "what_we_are.section.1.title": "",
                "what_we_are.section.1.description": "",
                "what_we_are.section.2.title": "",
                "what_we_are.section.2.description": "",
                "what_we_are.section.3.title": "",
                "what_we_are.section.3.description": "",
                "what_we_do.1.title": "",
                "what_we_do.1.description": "",
                "what_we_do.2.title": "",
                "what_we_do.2.description": "",
                "we_are_passionate.header.title": "",
                "we_are_passionate.header.subtitle": "",
                "we_are_passionate.1.title": "",
                "we_are_passionate.1.description": "",
                "we_are_passionate.2.title": "",
                "we_are_passionate.2.description": "",
                "we_are_passionate.3.title": "",
                "we_are_passionate.3.description": "",
                "our_clients.header.title": "",
                "our_clients.header.subtitle": "",
                "our_clients.outpass.client_info": "",
                "our_clients.outpass.body": "",
                "our_clients.sorbonne.client_info": "",
                "our_clients.sorbonne.body": "",
                "our_clients.hooderie.client_info": "",
                "our_clients.hooderie.body": "",
                "our_clients.etb.client_info": "",
                "our_clients.etb.body": "",
                "conversation.header.title": "",
                "conversation.header.subtitle": "",
                "conversation.message.client.1.author": "",
                "conversation.message.client.1.infos": "",
                "conversation.message.client.1.text": "",
                "conversation.message.president.author": "",
                "conversation.message.president.infos": "",
                "conversation.message.president.text": "",
                "conversation.message.client.2.author": "",
                "conversation.message.client.2.infos": "",
                "conversation.message.client.2.text": "",
                "conversation.message.director.author": "",
                "conversation.message.director.infos": "",
                "conversation.message.director.text": "",
                "write_next_message.header.title": "",
                "write_next_message.header.subtitle": "",
                "footer.1.title": "",
                "footer.1.description": "",
                "footer.2.title": "",
                "footer.2.description": "",
                "footer.3.title": "",
                "footer.3.description": "",
                "footer.copyright": "",
            }
        }
    },
    fallbackLng: "fr",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
